import Swiper, { Navigation, Pagination } from 'swiper';
import jquery from 'jquery';
import 'jquery-ui-bundle';

let swiper;
window.addEventListener(
    "load",
    function () {
        // init Swiper:
        swiper = new Swiper(".ausschnittSwiper", {
            modules: [Navigation, Pagination],
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },
    false
);

document.addEventListener('click', function (e) {
    const target = e.target

    // Open modal
    if (target.hasAttribute('data-toggle') && target.getAttribute('data-toggle') === 'modal') {
        if (target.hasAttribute('data-target')) {
            document.getElementById(target.getAttribute('data-target')).classList.add('open');
            swiper.slideTo(parseInt(target.getAttribute('data-slide-id')));
            e.preventDefault();
        }
    }

    // Close modal window with 'data-dismiss' attribute or when the backdrop is clicked
    if ((target.hasAttribute('data-dismiss') && target.getAttribute('data-dismiss') === 'modal') || target.classList.contains('modal')) {
        document.querySelector('[class="modal open"]').classList.remove('open');
        e.preventDefault();
    }
}, false);

window.$ = jquery;
var availablebanks = [];
$.ajax({
    type: "POST",
    url:  "/functions/functions.php",
    success: function(data){
        var numberOfElements = Object.keys(data).length;
        var blz_ort = "";

        for(var i = 0; i < numberOfElements; i++){
            blz_ort = data[i]['BLZ'];
            availablebanks.push(blz_ort);
        }

        $("#banks").autocomplete({
            html:false,
            source: availablebanks,
            appendTo: '#form-field-input-autocomplete'
        });

    },
    error: function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR);
        console.log(errorThrown);
    }
});
